<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  mounted() {
    this.joinWorkspace({
      invitationId: this.$route.query.invitationId,
      workspaceId: this.$route.query.workspaceId
    });
  },
  methods: {
    ...mapActions(['joinWorkspace'])
  }
};
</script>
